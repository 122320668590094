.slick-prev:before {
  color: orange;
}
.slick-next:before {
  color: orange;
}

.icon {
  font-size: 30px;
  color: yellow;
  transition: 0.3s ease-in;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
}

.icon-menu {
  font-size: 30px;
  color: yellow;
  transition: 0.3s ease-in;
  cursor: pointer;
  display: none;
}

@media (max-width: 768px) {
  .icon-menu {
    display: block;
  }
}
